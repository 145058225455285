import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
const AUTH_API = 'https://api.v2.lbpay.uat.bugzstudio.com/';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  
  constructor(private http: HttpClient) { }
  //This login function in return either gives jwt token or an error message
  login(username: string, password: string,appCountryId:number): Observable<any> {
    return this.http.post(AUTH_API + 'account/login', {
      username,
      password,
      appCountryId
    }, httpOptions);
}
refreshToken(token: string) {
  return this.http.post(AUTH_API + 'account/refreshTokens', {
    refreshToken: token
  }, httpOptions);
}
}
