import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },   {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./forget-password/forget-password.module').then( m => m.ForgetPasswordPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'redeem',
    loadChildren: () => import('./redeem/redeem.module').then( m => m.RedeemPageModule)
  },
  {
    path: 'redeem-details',
    loadChildren: () => import('./redeem-details/redeem-details.module').then( m => m.RedeemDetailsPageModule)
  },
  {
    path: 'report-an-issue',
    loadChildren: () => import('./report-an-issue/report-an-issue.module').then( m => m.ReportAnIssuePageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'transaction-history',
    loadChildren: () => import('./transaction-history/transaction-history.module').then( m => m.TransactionHistoryPageModule)
  },
  {
    path: 'wallet-dashboard',
    loadChildren: () => import('./wallet-dashboard/wallet-dashboard.module').then( m => m.WalletDashboardPageModule)
  },
  {
    path: 'wallet-topup',
    loadChildren: () => import('./wallet-topup/wallet-topup.module').then( m => m.WalletTopupPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'menu-drawer',
    loadChildren: () => import('./menu-drawer/menu-drawer.module').then( m => m.MenuDrawerPageModule)
  },
  {
    path: 'buttom-drawer',
    loadChildren: () => import('./buttom-drawer/buttom-drawer.module').then( m => m.ButtomDrawerPageModule)
  },
  {
    path: 'buttom-locate-drawer',
    loadChildren: () => import('./buttom-locate-drawer/buttom-locate-drawer.module').then( m => m.ButtomLocateDrawerPageModule)
  },
  {
    path: 'news-details',
    loadChildren: () => import('./news-details/news-details.module').then( m => m.NewsDetailsPageModule)
  },
  {
    path: 'news-listing',
    loadChildren: () => import('./news-listing/news-listing.module').then( m => m.NewsListingPageModule)
  },
  {
    path: 'timeout',
    loadChildren: () => import('./timeout/timeout.module').then( m => m.TimeoutPageModule)
  },
  {
    path: 'payviaqr',
    loadChildren: () => import('./payviaqr/payviaqr.module').then( m => m.PayviaqrPageModule)
  },
  {
    path: 'failed-transaction',
    loadChildren: () => import('./failed-transaction/failed-transaction.module').then( m => m.FailedTransactionPageModule)
  },
  {
    path: 'successful-transaction',
    loadChildren: () => import('./successful-transaction/successful-transaction.module').then( m => m.SuccessfulTransactionPageModule)
  },
  {
    path: 'select-token',
    loadChildren: () => import('./select-token/select-token.module').then( m => m.SelectTokenPageModule)
  },
   
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
